<template>
    <div style="background: #F4F4F4; padding-top: 20px;">
        <div class="all">
            <div class="title">{{ $i18n.locale != 'en-us' ? '形态列表搜索' : 'Morphological list search' }}</div>
            <el-form label-position="left" label-width="80px" :model="formLabelAlign" class="form flex wrap"
                ref="formLabelAlign">

                <!-- 属名 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '属名:' : 'genus'" class="red">
                    <el-input v-model="formLabelAlign.genus" :placeholder="$i18n.locale != 'en-us' ? '属名' : 'genus'">
                    </el-input>
                </el-form-item>

                <!-- 命名人 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '命名人:' : 'nomenclator'" class="blue">
                    <el-input v-model="formLabelAlign.nomenclator"
                        :placeholder="$i18n.locale != 'en-us' ? '命名人' : 'nomenclator'">
                    </el-input>
                </el-form-item>


                <!-- 命名年 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '命名年:' : 'defineYear'" class="green">
                    <el-input v-model="formLabelAlign.defineYear"
                        :placeholder="$i18n.locale != 'en-us' ? '命名年' : 'defineYear'">
                    </el-input>
                </el-form-item>

                <!-- 修订人 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '修订人:' : 'reviser'" class="orange">
                    <el-input v-model="formLabelAlign.reviser"
                        :placeholder="$i18n.locale != 'en-us' ? '修订人' : 'reviser'">
                    </el-input>
                </el-form-item>

                <!-- 修订年 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '修订年:' : 'reviseryear'" class="red">
                    <el-input v-model="formLabelAlign.reviseryear"
                        :placeholder="$i18n.locale != 'en-us' ? '修订年' : 'reviseryear'">
                    </el-input>
                </el-form-item>

                <!-- 中文名 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '中文名:' : 'nameCn'" class="blue">
                    <el-input v-model="formLabelAlign.nameCn" :placeholder="$i18n.locale != 'en-us' ? '中文名' : 'nameCn'">
                    </el-input>
                </el-form-item>

                <!-- 模式种 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '模式种:' : 'typeSpecies'" class="green">
                    <el-input v-model="formLabelAlign.typeSpecies"
                        :placeholder="$i18n.locale != 'en-us' ? '模式种' : 'typeSpecies'">
                    </el-input>
                </el-form-item>

                <!-- 备注1 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '备注1:' : 'remark1'" class="orange w100">
                    <el-input v-model="formLabelAlign.remark1"
                        :placeholder="$i18n.locale != 'en-us' ? '备注1' : ' remark1'">
                    </el-input>
                </el-form-item>

                <!-- 分类 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '分类' : 'Classification'" class="red">
                    <el-select v-model="formLabelAlign.classification"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择分类' : 'please select Classification'"
                        @change="resetForm('formLabelAlign')">
                        <el-option v-for="item in selectList.classification" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 亲缘关系 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '亲缘关系:' : 'affiliation'" class="orange">
                    <el-input v-model="formLabelAlign.affiliation"
                        :placeholder="$i18n.locale != 'en-us' ? '亲缘关系' : 'please select affiliation'">
                    </el-input>
                </el-form-item>

                <!-- 分布时代（始） -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '分布时代(始):' : 'Age (from)'" class="blue">
                    <el-select v-model="formLabelAlign.ageFrom"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择分布时代(始)' : 'please select Age (from)'" multiple>
                        <el-option v-for="item in selectList.agefrom" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 分布时代（末） -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '分布时代(末):' : 'Age (to)'" class="green">
                    <el-select v-model="formLabelAlign.ageTo"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择分布时代(末)' : 'please select Age (to)'" multiple>
                        <el-option v-for="item in selectList.ageto" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 产地 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '产地:' : 'locality'" class="orange">
                    <el-input v-model="formLabelAlign.locality"
                        :placeholder="$i18n.locale != 'en-us' ? '产地' : 'locality'">
                    </el-input>
                </el-form-item>

                <!-- 层位 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '层位:' : 'horizon'" class="red">
                    <el-input v-model="formLabelAlign.horizon"
                        :placeholder="$i18n.locale != 'en-us' ? '层位' : 'horizon'">
                    </el-input>
                </el-form-item>

                <!-- 离散形式 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '离散形式:' : 'Discrete version'" class="orange"
                    v-if="getflag('name')">
                    <el-select v-model="formLabelAlign.formDetails.discreteVersion"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择离散形式' : 'please select Discrete version'" multiple>
                        <el-option v-for="item in selectList.name" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 形状 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '形状:' : 'shape'" class="red" v-if="getflag('shape')">
                    <el-select v-model="formLabelAlign.formDetails.shape"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择形状' : 'please select shape'" multiple>
                        <el-option v-for="item in selectList.shape" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 侧面轮廓 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '侧面轮廓:' : 'profile'" class="blue" v-if="getflag('type')"
                    multiple>
                    <el-select v-model="formLabelAlign.formDetails.profile"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择侧面轮廓' : 'please select profile'">
                        <el-option v-for="item in selectList.type" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 大小 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '大小:' : 'size'" class="green" v-if="getflag('size')">
                    <el-input v-model="formLabelAlign.formDetails.size"
                        :placeholder="$i18n.locale != 'en-us' ? '大小' : 'please select size'" type="number" min="0">
                    </el-input>
                </el-form-item>

                <!-- 极轴长度 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '极轴长度:' : '极轴长度'" class="green"
                    v-if="getflag('gzlength')">
                    <el-input v-model="formLabelAlign.formDetails.gzlength"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择极轴长度' : 'please select 极轴长度'" type="number" min="0">
                    </el-input>
                </el-form-item>

                <!-- 赤道轮廓 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '赤道轮廓:' : 'equatorial contour'" class="orange"
                    v-if="getflag('age')">
                    <el-select v-model="formLabelAlign.age.id"
                        :placeholder="$i18n.locale != 'en-us' ? '赤道轮廓' : 'please select equatorial contour'" multiple
                        @change="clicka">
                        <el-option v-for="item in selectList.age" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 赤道轮廓子选项 -->
                <div class="chlidren" v-for="(iu, iuindex) in formLabelAlign.age.id" :key="iu" v-show="getflag('age')">
                    <el-form-item v-if="iuindex == 0" :label="$i18n.locale != 'en-us' ? '直径:' : 'diameter'">
                        <el-input v-model="formLabelAlign.formDetails.diameter" class="input"
                            :placeholder="$i18n.locale != 'en-us' ? '直径' : 'diameter'" type="number" min="0">
                        </el-input>
                    </el-form-item>

                    <el-form-item v-for="(item, index) in selectList.age[iu].chlidren" :key="item.value"
                        :label="($i18n.locale != 'en-us' ? item.name : item.nameEN) + ':'" class="orange"
                        v-show="item.name != '直径'">
                        <!-- <el-input v-model="formLabelAlign.age.diameter" class="input" placeholder="直径" v-if="false"
                            type="number" min="0">
                        </el-input> -->
                        <el-select v-model="formLabelAlign.formDetails[item.value]" v-if="item.name != '直径'"
                            :placeholder="$i18n.locale != 'en-us' ? ('请选择' + item.name) : ('please select' + item.nameEN)"
                            multiple>
                            <el-option v-for="item2 in selectList.age[iu].chlidren[index].list" :key="item2.value"
                                :label="$i18n.locale != 'en-us' ? item2.name : item2.nameEN" :value="item2.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <!-- 轮廓线 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '轮廓线:' : '轮廓线'" class="red" v-if="getflag('line')">
                    <el-select v-model="formLabelAlign.formDetails.contourLine"
                        :placeholder="$i18n.locale != 'en-us' ? '轮廓线' : 'please select 轮廓线'" multiple>
                        <el-option v-for="item in selectList.line" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 外壁厚度 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '外壁厚度:' : '外壁厚度'" class="blue" v-if="getflag('ply')">
                    <el-select v-model="formLabelAlign.formDetails.outerWallThickness"
                        :placeholder="$i18n.locale != 'en-us' ? '外壁厚度' : 'please select 外壁厚度'" multiple>
                        <el-option v-for="item in selectList.ply" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 厚度 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '厚度 (微米):' : 'thickness (micron)'" class="green"
                    v-if="getflag('thickness')">
                    <el-input v-model="formLabelAlign.formDetails.thickness"
                        :placeholder="$i18n.locale != 'en-us' ? '厚度 (微米)' : 'thickness (micron)'" type="number" min="0">
                    </el-input>
                </el-form-item>

                <!-- 表面纹饰 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '表面纹饰:' : '表面纹饰'" class="green"
                    v-if="getflag('emblazonry')" prop="emblazonry">
                    <el-select v-model="formLabelAlign.emblazonry.id"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择表面纹饰' : 'please select 表面纹饰'" multiple>
                        <el-option v-for="item in selectList.emblazonry" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.id"
                            v-show="getcflag(item.type, item.id)">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 表面纹饰子选项 -->
                <div class="chlidren" v-for="iu in formLabelAlign.emblazonry.id" :key="iu"
                    v-show="getflag('emblazonry')">
                    <el-form-item v-for="(item, index) in selectList.emblazonry[iu].chlidren" :key="item.value"
                        :label="($i18n.locale != 'en-us' ? item.name : item.nameEN) + ':'" class="orange">
                        <el-select v-model="formLabelAlign.formDetails[item.value]"
                            :placeholder="$i18n.locale != 'en-us' ? ('请选择' + item.name) : ('please select' + item.nameEN)"
                            multiple>
                            <el-option v-for="item2 in selectList.emblazonry[iu].chlidren[index].list"
                                :key="item2.value" :label="$i18n.locale != 'en-us' ? item2.name : item2.nameEN"
                                :value="item2.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <!-- 远极面纹饰 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '远极面纹饰:' : 'distal face'" class="orange"
                    v-if="getflag('far')">
                    <el-select v-model="formLabelAlign.formDetails.distalFace"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择远极面纹饰' : 'please select distal face'" multiple>
                        <el-option v-for="item in selectList.far" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 近极面纹饰 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '近极面纹饰:' : 'near'" class="red" v-if="getflag('near')">
                    <el-select v-model="formLabelAlign.formDetails.proximalFace"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择近极面纹饰' : 'please select distal near'" multiple>
                        <el-option v-for="item in selectList.near" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 环 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '环:' : 'Zona'" class="blue" v-if="getflag('loop')">
                    <el-select v-model="formLabelAlign.loop.id"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择环' : 'please select distal Zona'" multiple>
                        <el-option v-for="item in selectList.loop" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 环子选项 -->
                <div class="chlidren" v-for="iu in formLabelAlign.loop.id" :key="iu" v-show="getflag('loop')">
                    <el-form-item v-for="(item, index) in selectList.loop[iu].chlidren" :key="item.value"
                        :label="($i18n.locale != 'en-us' ? item.name : item.nameEN) + ':'" class="orange">
                        <el-select v-model="formLabelAlign.formDetails[item.value]"
                            :placeholder="$i18n.locale != 'en-us' ? ('请选择' + item.name) : ('please select' + item.nameEN)"
                            multiple>
                            <el-option v-for="item2 in selectList.loop[iu].chlidren[index].list" :key="item2.value"
                                :label="$i18n.locale != 'en-us' ? item2.name : item2.nameEN" :value="item2.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <!-- 周壁 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '周壁:' : '周壁'" class="green"
                    v-if="getflag('perisporium')">
                    <el-select v-model="formLabelAlign.formDetails.perisporium"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择周壁' : 'please select distal 周壁'">
                        <el-option v-for="item in selectList.perisporium" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 囊 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '囊:' : 'saccus'" class="orange"
                    v-if="getflag('bladder')">
                    <el-select v-model="formLabelAlign.bladder.id"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择囊' : 'please select distal saccus'" multiple>

                        <el-option v-for="item in selectList.bladder" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 囊子选项 -->
                <div class="chlidren" v-for="iu in formLabelAlign.bladder.id" :key="iu" v-show="getflag('bladder')">
                    <el-form-item v-for="(item, index) in selectList.bladder[iu].chlidren" :key="item.value"
                        :label="($i18n.locale != 'en-us' ? item.name : item.nameEN) + ':'" class="orange">
                        <el-select v-model="formLabelAlign.formDetails[item.value]"
                            :placeholder="$i18n.locale != 'en-us' ? ('请选择' + item.name) : ('please select' + item.nameEN)"
                            multiple>
                            <el-option v-for="item2 in selectList.bladder[iu].chlidren[index].list" :key="item2.value"
                                :label="$i18n.locale != 'en-us' ? item2.name : item2.nameEN" :value="item2.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <!-- 萌发口器 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '萌发口器:' : 'aperture'" class="red"
                    v-if="getflag('trophi')">
                    <el-select v-model="formLabelAlign.trophi.id"
                        :placeholder="$i18n.locale != 'en-us' ? '请选择萌发口器' : 'please select distal aperture'" multiple>
                        <el-option v-for="item in selectList.trophi" :key="item.value"
                            :label="$i18n.locale != 'en-us' ? item.name : item.nameEN" :value="item.id"
                            v-show="getcflag(item.type)">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- 萌发口器子选项 -->
                <div class="chlidren" v-for="iu in formLabelAlign.trophi.id" :key="iu" v-show="getflag('trophi')">

                    <el-form-item v-for="(item, index) in selectList.trophi[iu].chlidren" :key="item.value"
                        :label="($i18n.locale != 'en-us' ? item.name : item.nameEN) + ':'" class="orange">
                        <el-input v-model="formLabelAlign.formDetails.pore" class="input"
                            :placeholder="$i18n.locale != 'en-us' ? '孔径' : '孔径'" v-if="item.name == '孔径'" type="number"
                            min="0">
                        </el-input>
                        <el-input v-model="formLabelAlign.formDetails.widthOfAnnulus" class="input"
                            :placeholder="$i18n.locale != 'en-us' ? '环宽度' : '环宽度'" v-else-if="item.name == '环宽度'"
                            type="number" min="0">
                        </el-input>
                        <el-select v-model="formLabelAlign.formDetails[item.value]"
                            :placeholder="$i18n.locale != 'en-us' ? ('请选择' + item.name) : ('please select' + item.nameEN)"
                            multiple v-else>
                            <el-option v-for="item2 in selectList.trophi[iu].chlidren[index].list" :key="item2.value"
                                :label="$i18n.locale != 'en-us' ? item2.name : item2.nameEN" :value="item2.value">
                            </el-option>
                        </el-select>
                    </el-form-item>


                </div>


                <!-- 属征 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '属征:' : 'genericCharacters'" class="orange">
                    <el-input v-model="formLabelAlign.genericCharacters"
                        :placeholder="$i18n.locale != 'en-us' ? '属征' : ' genericCharacters'">
                    </el-input>
                </el-form-item>



                <!-- 备注2 -->
                <el-form-item :label="$i18n.locale != 'en-us' ? '备注2:' : 'remark2'" class="orange">
                    <el-input v-model="formLabelAlign.remark2"
                        :placeholder="$i18n.locale != 'en-us' ? '备注2' : ' remark2'">
                    </el-input>
                </el-form-item>

            </el-form>

            <el-button type="primary" @click="getprops" class="btn">搜索</el-button>
            <!-- <button @click="getList">查看</button> -->
        </div>
    </div>


</template>

<script>
import formApi from '@/api/form/index';
import { mapMutations } from 'vuex';

export default {
    data() {
        return {
            formLabelAlign: {
                genus: '',
                nomenclator: '',
                defineYear: '',
                reviser: '',
                reviseryear: '',
                nameCn: '',
                typeSpecies: '',
                remark1: '',
                classification: '',
                affiliation: '',
                ageFrom: '',
                ageTo: '',
                locality: '',
                horizon: '',
                formDetails: {},
                name: '',
                shape: '',
                type: '',
                size: '',
                gzlength: '',
                age: {
                    id: []
                },
                line: '',
                ply: '',
                thickness: '',
                emblazonry: {
                    id: []
                },
                far: '',
                near: '',
                loop: {
                    id: []
                },
                perisporium: {},
                bladder: {
                    id: []
                },
                trophi: {
                    id: []
                },
                genericCharacters: '',
                remark2: ''
            },
            selectList: {
                classification: [{
                    name: "菌类孢子",
                    nameEN: "Fungi",
                    value: "菌类孢子",
                },
                {
                    name: "藻类",
                    nameEN: "Algae",
                    value: "藻类"
                },
                {
                    name: "蕨类植物孢子",
                    nameEN: "Fern_spores",
                    value: "蕨类植物孢子"
                },
                {
                    name: "裸子植物花粉",
                    nameEN: "Gymnosperm_pollen",
                    value: "裸子植物花粉"
                },
                {
                    name: "被子植物花粉",
                    nameEN: "Angiosperm_pollen",
                    value: "被子植物花粉"
                }],
                agefrom: [
                    {
                        name: "上新世",
                        nameEN: "上新世",
                        value: "上新世"
                    },
                    {
                        name: "中新世",
                        nameEN: "中新世",
                        value: "中新世"
                    },
                    {
                        name: "中生代",
                        nameEN: "中生代",
                        value: "中生代"
                    },
                    {
                        name: "新生代",
                        nameEN: "新生代",
                        value: "新生代"
                    },
                    {
                        name: "渐新世",
                        nameEN: "渐新世",
                        value: "渐新世"
                    },
                    {
                        name: "始新世",
                        nameEN: "始新世",
                        value: "始新世"
                    },
                    {
                        name: "古新世",
                        nameEN: "古新世",
                        value: "古新世"
                    },
                    {
                        name: "新近纪",
                        nameEN: "新近纪",
                        value: "新近纪"
                    },
                    {
                        name: "古近纪",
                        nameEN: "古近纪",
                        value: "古近纪"
                    },
                    {
                        name: "晚白垩世",
                        nameEN: "晚白垩世",
                        value: "晚白垩世"
                    }
                ],
                ageto: [{
                    name: "上新世",
                    nameEN: "上新世",
                    value: "上新世"
                },
                {
                    name: "中新世",
                    nameEN: "中新世",
                    value: "中新世"
                },
                {
                    name: "中生代",
                    nameEN: "中生代",
                    value: "中生代"
                },
                {
                    name: "新生代",
                    nameEN: "新生代",
                    value: "新生代"
                },
                {
                    name: "渐新世",
                    nameEN: "渐新世",
                    value: "渐新世"
                },
                {
                    name: "始新世",
                    nameEN: "始新世",
                    value: "始新世"
                },
                {
                    name: "古新世",
                    nameEN: "古新世",
                    value: "古新世"
                },
                {
                    name: "新近纪",
                    nameEN: "新近纪",
                    value: "新近纪"
                },
                {
                    name: "古近纪",
                    nameEN: "古近纪",
                    value: "古近纪"
                },
                {
                    name: "晚白垩世",
                    nameEN: "晚白垩世",
                    value: "晚白垩世"
                }],
                name: [
                    {
                        name: "单细胞",
                        nameEN: "onecellate",
                        value: "单细胞"
                    },
                    {
                        name: "多细胞",
                        nameEN: "multicellate",
                        value: "多细胞"
                    },
                    {
                        name: "单孢体",
                        nameEN: "单孢体",
                        value: "单孢体"
                    },
                    {
                        name: "四孢体",
                        nameEN: "tetrahedral",
                        value: "四孢体"
                    },
                    {
                        name: "单粒花粉",
                        nameEN: "monad",
                        value: "单粒花粉"
                    },
                    {
                        name: "复合花粉",
                        nameEN: "complex pollen",
                        value: "复合花粉"
                    }
                ],
                shape: [
                    {
                        name: "圆球形",
                        nameEN: "sphaeroidati",
                        value: "圆球形"
                    },
                    {
                        name: "长球形",
                        nameEN: "prolate",
                        value: "长球形"
                    },
                    {
                        name: "扁球形",
                        nameEN: "oblati",
                        value: "扁球形"
                    },
                    {
                        name: "透镜体形",
                        nameEN: "透镜体形",
                        value: "透镜体形"
                    },
                    {
                        name: "三瓣状",
                        nameEN: "trilobate",
                        value: "三瓣状"
                    },
                    {
                        name: "倒梨形",
                        nameEN: "倒梨形",
                        value: "倒梨形"
                    },
                    {
                        name: "纺锤形",
                        nameEN: "纺锤形",
                        value: "纺锤形"
                    },
                    {
                        name: "双椎体形",
                        nameEN: "双椎体形",
                        value: "双椎体形"
                    }
                ],
                type: [
                    {
                        name: "圆形",
                        nameEN: "circular",
                        value: "圆形"
                    },
                    {
                        name: "椭圆形",
                        nameEN: "elliptical",
                        value: "椭圆形"
                    },
                    {
                        name: "宽椭圆形",
                        nameEN: "broad-elliptic",
                        value: "宽椭圆形"
                    },
                    {
                        name: "扁圆形",
                        nameEN: "扁圆形",
                        value: "扁圆形"
                    },
                    {
                        name: "赤道部位凹陷",
                        nameEN: "赤道部位凹陷",
                        value: "赤道部位凹陷"
                    },
                    {
                        name: "菱形",
                        nameEN: "菱形",
                        value: "菱形"
                    },
                    {
                        name: "豆形或肾形",
                        nameEN: "豆形或肾形",
                        value: "豆形或肾形"
                    }
                ],
                size: [],
                gzlength: [],
                age: [
                    {
                        id: 0,
                        name: "三角形",
                        nameEN: "triangular",
                        value: "三角形",
                        chlidren: [{
                            name: "直径",
                            nameEN: "diameter",
                            propos: "diameter",
                            value: "diameter",
                            list: []
                        },
                        {
                            name: "三边状态",
                            nameEN: "三边状态",
                            value: "threeSidesState",
                            list: [{
                                name: "平直",
                                nameEN: "striaight",
                                value: "平直"
                            }, {
                                name: "内凹",
                                nameEN: "concave",
                                value: "内凹"
                            }, {
                                name: "外凸",
                                nameEN: "convex",
                                value: "外凸"
                            }, {
                                name: "沿赤道部位加厚",
                                nameEN: "沿赤道部位加厚",
                                value: "沿赤道部位加厚"
                            }]
                        }, {
                            name: "角部特征",
                            nameEN: "角部特征",
                            value: "featureOfCorner",
                            list: [{
                                name: "具有与射线正交或斜交的褶皱",
                                nameEN: "具有与射线正交或斜交的褶皱",
                                value: "具有与射线正交或斜交的褶皱"
                            }, {
                                name: "浑圆",
                                nameEN: "round",
                                value: "浑圆"
                            }, {
                                name: "锐圆",
                                nameEN: "angular",
                                value: "锐圆"
                            }, {
                                name: "尖弧形",
                                nameEN: "尖弧形",
                                value: "尖弧形"
                            }, {
                                name: "纹饰在角部变大集中",
                                nameEN: "纹饰在角部变大集中",
                                value: "纹饰在角部变大集中"
                            }]
                        }]
                    },
                    {
                        id: 1,
                        name: "圆三角形",
                        nameEN: "rounded-triangular",
                        value: "圆三角形",
                        chlidren: [{
                            name: "直径",
                            nameEN: "diameter",
                            value: "直径",
                            list: []
                        },]
                    },
                    {
                        id: 2,
                        name: "近圆形",
                        nameEN: "subcircular",
                        value: "近圆形",
                        chlidren: [{
                            name: "直径",
                            nameEN: "diameter",
                            value: "直径",
                            list: []
                        },]
                    },
                    {
                        id: 3,
                        name: "三裂圆形",
                        nameEN: "trilobate-circular",
                        value: "三裂圆形",
                        chlidren: [{
                            name: "直径",
                            nameEN: "diameter",
                            value: "直径",
                            list: []
                        },]
                    }, {
                        id: 4,
                        name: "三瓣状",
                        nameEN: "trilobate",
                        value: "三瓣状",
                        chlidren: [{
                            name: "直径",
                            nameEN: "diameter",
                            value: "直径",
                            list: []
                        },]
                    },
                    {
                        id: 5,
                        name: "菱形",
                        nameEN: "rhombus",
                        value: "菱形",
                        chlidren: [{
                            name: "直径",
                            nameEN: "diameter",
                            value: "直径",
                            list: []
                        },]
                    },
                    {
                        id: 6,
                        name: "纺锤形",
                        nameEN: "fusiform",
                        value: "纺锤形",
                        chlidren: [{
                            name: "直径",
                            nameEN: "diameter",
                            value: "直径",
                            list: []
                        },]
                    },
                    {
                        id: 7,
                        name: "六角形",
                        nameEN: "hexagon",
                        value: "六角形",
                        chlidren: [{
                            name: "直径",
                            nameEN: "diameter",
                            value: "直径",
                            list: []
                        },]
                    },
                    {
                        id: 8,
                        name: "豆形或肾形",
                        nameEN: "豆形或肾形",
                        value: "豆形或肾形",
                        chlidren: [{
                            name: "直径",
                            nameEN: "diameter",
                            value: "直径",
                            list: []
                        },]
                    }
                ],
                line: [{
                    name: "平直",
                    nameEN: "平直",
                    value: "平直"
                },
                {
                    name: "微波状",
                    nameEN: "微波状",
                    value: "微波状"
                },
                {
                    name: "细齿状",
                    nameEN: "细齿状",
                    value: "细齿状"
                },
                ],
                ply: [{
                    name: "薄",
                    nameEN: "thin",
                    value: "薄"
                }, {
                    name: "厚",
                    nameEN: "thick",
                    value: "厚"
                },
                {
                    name: "单层",
                    nameEN: "单层",
                    value: "单层"
                }, {
                    name: "双层",
                    nameEN: "双层",
                    value: "双层"
                }
                ],
                thickness: [],
                emblazonry: [{
                    id: 0,
                    name: "近光面系",
                    nameEN: "sublaevigati",
                    value: "近光面系",
                    chlidren: [],
                    type: "A"
                },
                {
                    id: 1,
                    name: "刺粒面系",
                    nameEN: "apiculati",
                    value: "刺粒面系",
                    chlidren: [],
                    type: "A"
                },
                {
                    id: 2,
                    name: "颗粒面亚系",
                    nameEN: "granulati",
                    value: "颗粒面亚系",
                    chlidren: [],
                    type: "A"
                },
                {
                    id: 3,
                    name: "块瘤面亚系",
                    nameEN: "verrucati",
                    value: "块瘤面亚系",
                    chlidren: [],
                    type: "Q",
                },
                {
                    id: 4,
                    name: "刺面亚系",
                    nameEN: "apiculati",
                    value: "刺面亚系",
                    chlidren: [],
                    type: "Q"
                },
                {
                    id: 5,
                    name: "乳头系",
                    nameEN: "papilla",
                    value: "乳头系",
                    chlidren: [],
                    type: "A"
                },
                {
                    id: 6,
                    name: "粒面系",
                    nameEN: "granule",
                    value: "粒面系",
                    chlidren: [],
                    type: "A"
                },
                {
                    id: 7,
                    name: "光面",
                    nameEN: "laevigati",
                    value: "光面",
                    chlidren: [],
                    type: "CD"
                },
                {
                    id: 8,
                    name: "近光",
                    nameEN: "sublaevigati",
                    value: "近光",
                    chlidren: [],
                    type: "CD"
                },
                {
                    id: 9,
                    name: "刺粒",
                    nameEN: "apiculati",
                    value: "刺粒",
                    chlidren: [],
                    type: "C"
                },
                {
                    id: 10,
                    name: "颗粒",
                    nameEN: "granulati",
                    value: "颗粒",
                    chlidren: [],
                    type: "C"
                },
                {
                    id: 11,
                    name: "块瘤",
                    nameEN: "verrucati",
                    value: "块瘤",
                    chlidren: [],
                    type: "C"
                },
                {
                    id: 12,
                    name: "刺",
                    nameEN: "nodati",
                    value: "刺",
                    chlidren: [],
                    type: "CD"
                },
                {
                    id: 13,
                    name: "网穴",
                    nameEN: "murornati",
                    value: "网穴",
                    type: "CE",
                    chlidren: [{
                        name: "网眼",
                        nameEN: "网眼",
                        value: "lumina",
                        list: [{
                            name: "圆形",
                            nameEN: "",
                            value: "圆形"
                        }, {
                            name: "多角形",
                            nameEN: "",
                            value: "多角形"
                        }, {
                            name: "方形",
                            nameEN: "",
                            value: "方形"
                        }, {
                            name: "大",
                            nameEN: "",
                            value: "大"
                        }, {
                            name: "小",
                            nameEN: "",
                            value: "小"
                        }, {
                            name: "规则",
                            nameEN: "",
                            value: "规则"
                        }, {
                            name: "不规则",
                            nameEN: "",
                            value: "不规则"
                        }]
                    }, {
                        name: "网脊",
                        nameEN: "网脊",
                        value: "muri",
                        list: [{
                            name: "粗",
                            nameEN: "",
                            value: "粗"
                        }, {
                            name: "细",
                            nameEN: "",
                            value: "细"
                        }, {
                            name: "由颗粒组成",
                            nameEN: "",
                            value: "由颗粒组成"
                        }, {
                            name: "冠脊",
                            nameEN: "",
                            value: "冠脊"
                        }]
                    }, {
                        name: "分布",
                        nameEN: "分布",
                        value: "wireSideDistribute",
                        list: [{
                            name: "向极区变细",
                            nameEN: "",
                            value: "向极区变细"
                        }, {
                            name: "向极区变粗",
                            nameEN: "",
                            value: "向极区变粗"
                        }, {
                            name: "向沟边变细",
                            nameEN: "",
                            value: "向沟边变细"
                        }, {
                            name: "向沟边变粗",
                            nameEN: "",
                            value: "向沟边变粗"
                        }]
                    }]
                },
                {
                    id: 14,
                    name: "条带状至条痕状系",
                    nameEN: "cicatricosi to canaliculati",
                    value: "条带状至条痕状系",
                    chlidren: [],
                    type: "C"
                },
                {
                    id: 15,
                    name: "乳头",
                    nameEN: "tuberini",
                    value: "乳头",
                    chlidren: [],
                    type: "D"
                },
                {
                    id: 16,
                    name: "粒",
                    nameEN: "granulonapiti",
                    value: "粒",
                    chlidren: [],
                    type: "D"
                },
                {
                    id: 17,
                    name: "瘤",
                    nameEN: "subpilonapiti",
                    value: "瘤",
                    chlidren: [],
                    type: "D"
                },
                {
                    id: 18,
                    name: "网",
                    nameEN: "reticulonapiti",
                    value: "网",
                    chlidren: [],
                    type: "D"
                },
                {
                    id: 19,
                    name: "近光面",
                    nameEN: "laevigati",
                    value: "近光面",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 20,
                    name: "内点状",
                    nameEN: "intra-punctate",
                    value: "内点状",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 21,
                    name: "粒面",
                    nameEN: "granule",
                    value: "粒面",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 22,
                    name: "瘤面",
                    nameEN: "tuberculae",
                    value: "瘤面",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 23,
                    name: "刺面",
                    nameEN: "apiculati",
                    value: "刺面",
                    chlidren: [],
                    type: "E"
                },

                {
                    id: 24,
                    name: "棒瘤",
                    nameEN: "baculati",
                    value: "棒瘤",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 25,
                    name: "棒纹",
                    nameEN: "clava",
                    value: "棒纹",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 26,
                    name: "棒刺",
                    nameEN: "clava-spin",
                    value: "棒刺",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 27,
                    name: "杵状",
                    nameEN: "杵状",
                    value: "杵状",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 28,
                    name: "基柱-细棒结构",
                    nameEN: "pilum",
                    value: "基柱-细棒结构",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 29,
                    name: "条纹",
                    nameEN: "striate",
                    value: "条纹",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 30,
                    name: "弧形槽带",
                    nameEN: "弧形槽带",
                    value: "弧形槽带",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 31,
                    name: "脑纹，脑皱状",
                    nameEN: "cerebroid",
                    value: "脑纹，脑皱状",
                    chlidren: [],
                    type: "E"
                },

                {
                    id: 32,
                    name: "弱皱状（弱网状）",
                    nameEN: "弱皱状（弱网状）",
                    value: "弱皱状（弱网状）",
                    chlidren: [],
                    type: "E"
                },
                {
                    id: 33,
                    name: "具次生褶皱",
                    nameEN: "具次生褶皱",
                    value: "具次生褶皱",
                    chlidren: [],
                    type: "E"
                },],
                far: [
                    {
                        name: "四孢痕",
                        nameEN: "四孢痕",
                        value: "四孢痕"
                    },
                    {
                        name: "放射条纹",
                        nameEN: "放射条纹",
                        value: "放射条纹"
                    },
                    {
                        name: "块瘤",
                        nameEN: "块瘤",
                        value: "块瘤"
                    },
                    {
                        name: "刺纹",
                        nameEN: "刺纹",
                        value: "刺纹"
                    },
                    {
                        name: "变薄区",
                        nameEN: "analept",
                        value: "变薄区"
                    },
                    {
                        name: "近Y形",
                        nameEN: "近Y形",
                        value: "近Y形"
                    },
                    {
                        name: "弓形脊加厚",
                        nameEN: "弓形脊加厚",
                        value: "弓形脊加厚"
                    },
                    {
                        name: "同心圈状脊",
                        nameEN: "同心圈状脊",
                        value: "同心圈状脊"
                    }
                ],
                near: [{
                    name: "光滑",
                    nameEN: "光滑",
                    value: "光滑"
                },
                {
                    name: "退化的纹饰",
                    nameEN: "退化的纹饰",
                    value: "退化的纹饰"
                }],
                loop: [
                    {
                        id: 0,
                        name: "无环",
                        nameEN: "Azonal",
                        value: "无环",
                        chlidren: []
                    },
                    {
                        id: 1,
                        name: "具环",
                        nameEN: "Zonales",
                        value: "具环",
                        chlidren: [
                            {
                                name: "形状",
                                nameEN: "shape",
                                value: "shapeOfZonales",
                                list: [{
                                    name: "膜状",
                                    nameEN: "zonati",
                                    value: "膜状"
                                }, {
                                    name: "带状",
                                    nameEN: "cingulati",
                                    value: "带状"
                                }, {
                                    name: "波状",
                                    nameEN: "undulati",
                                    value: "波状"
                                }]
                            }, {
                                name: "宽度",
                                nameEN: "width",
                                value: "widthOfZonales",
                                list: [{
                                    name: "宽",
                                    nameEN: "wide",
                                    value: "宽"
                                }, {
                                    name: "窄",
                                    nameEN: "narrow",
                                    value: "窄"
                                }]
                            }, {
                                name: "厚度",
                                nameEN: "thickness",
                                value: "thicknessOfZonales",
                                list: [{
                                    name: "厚",
                                    nameEN: "thick",
                                    value: "厚"
                                }, {
                                    name: "薄",
                                    nameEN: "thin",
                                    value: "薄"
                                }]
                            },
                            {
                                name: "纹饰",
                                nameEN: "sculpture",
                                value: "sculpture",
                                list: [{
                                    name: "光滑",
                                    nameEN: "smooth",
                                    value: "光滑"
                                }, {
                                    name: "瘤状",
                                    nameEN: "verrucate",
                                    value: "瘤状"
                                }, {
                                    name: "念珠状",
                                    nameEN: "rosary",
                                    value: "念珠状"
                                }, {
                                    name: "棒粒状",
                                    nameEN: "clava-granulati",
                                    value: "棒粒状"
                                }]
                            }
                        ]
                    },
                ],
                perisporium: [{
                    name: "无",
                    nameEN: "not",
                    value: "无"
                },
                {
                    name: "有",
                    nameEN: "have",
                    value: "有"
                },],
                bladder: [{
                    id: 0,
                    name: "无囊",
                    nameEN: "not",
                    value: "无囊",
                    chlidren: []
                },
                {
                    id: 1,
                    name: "单囊亚类",
                    nameEN: "monosaccate",
                    value: "saccus",
                    chlidren: [
                        {
                            name: "宽度",
                            nameEN: "width",
                            value: "widthOfSaccus",
                            list: [{
                                name: "宽",
                                nameEN: "wide",
                                value: "宽"
                            }, {
                                name: "窄",
                                nameEN: "narrow",
                                value: "窄"
                            }, {
                                name: "等宽",
                                nameEN: "aequilate",
                                value: "等宽"
                            }, {
                                name: "不等宽",
                                nameEN: "不等宽",
                                value: "不等宽"
                            }]
                        }
                    ]
                },
                {
                    id: 2,
                    name: "双囊亚类",
                    nameEN: "disaccate",
                    value: "saccus",
                    chlidren: [
                        {
                            name: "栉",
                            nameEN: "cap ridge",
                            value: "capRidge",
                            list: [{
                                name: "无",
                                nameEN: "not",
                                value: "无"
                            }, {
                                name: "厚",
                                nameEN: "thick",
                                value: "厚"
                            }, {
                                name: "薄",
                                nameEN: "thin",
                                value: "薄"
                            }, {
                                name: "延伸至气囊",
                                nameEN: "延伸至气囊",
                                value: "延伸至气囊"
                            }, {
                                name: "具瘤皱状纹饰",
                                nameEN: "具瘤皱状纹饰",
                                value: "具瘤皱状纹饰"
                            }]
                        },
                        {
                            name: "气囊大小",
                            nameEN: "气囊大小",
                            value: "sizeOfGasbag",
                            list: [{
                                name: "大于半圆形",
                                nameEN: "大于半圆形",
                                value: "大于半圆形"
                            }, {
                                name: "小于半圆形",
                                nameEN: "小于半圆形",
                                value: "小于半圆形"
                            }, {
                                name: "最大直径寛于本体",
                                nameEN: "最大直径寛于本体",
                                value: "最大直径寛于本体"
                            }, {
                                name: "发育不完全",
                                nameEN: "发育不完全",
                                value: "发育不完全"
                            }, {
                                name: "小气囊",
                                nameEN: "小气囊",
                                value: "小气囊"
                            }]
                        },
                        {
                            name: "气囊位置",
                            nameEN: "气囊位置",
                            value: "positionOfGasbag",
                            list: [{
                                name: "本体腹侧",
                                nameEN: "本体腹侧",
                                value: "本体腹侧"
                            }, {
                                name: "本体两侧",
                                nameEN: "本体两侧",
                                value: "本体两侧"
                            }, {
                                name: "偏远极",
                                nameEN: "偏远极",
                                value: "偏远极"
                            }, {
                                name: "包围本体",
                                nameEN: "包围本体",
                                value: "包围本体"
                            }, {
                                name: "不规则分布",
                                nameEN: "不规则分布",
                                value: "不规则分布"
                            }]
                        }
                    ]
                },
                {
                    id: 3,
                    name: "多囊亚类",
                    nameEN: "polysacci",
                    value: "saccus",
                    chlidren: [
                        {
                            name: "气囊数量",
                            nameEN: "气囊数量",
                            value: "numberOfGasbag",
                            list: [{
                                name: "3",
                                nameEN: "3",
                                value: "3"
                            }, {
                                name: "大于3",
                                nameEN: "大于3",
                                value: "大于3"
                            }]
                        }
                    ]
                },],
                trophi: [{
                    id: 0,
                    name: "无",
                    nameEN: "not",
                    value: "无",
                    chlidren: [],
                    type: "ACDE"
                },
                {
                    id: 1,
                    name: "缝",
                    nameEN: "sutura",
                    value: "缝",
                    type: "C",
                    chlidren: [{
                        name: "缝数量",
                        nameEN: "缝数量",
                        value: "numberOfSutura",
                        list: [{
                            name: "单缝孢类",
                            nameEN: "monolete",
                            value: "单缝孢类"
                        }, {
                            name: "三缝孢类",
                            nameEN: "trilete",
                            value: "三缝孢类"
                        },]
                    },
                    {
                        name: "缝长度",
                        nameEN: "缝长度",
                        value: "lengthOfSutura",
                        list: [{
                            name: "小于1/2孢子半径",
                            nameEN: "小于1/2孢子半径",
                            value: "小于1/2孢子半径"
                        }, {
                            name: "1/2孢子半径至孢子半径",
                            nameEN: "1/2孢子半径至孢子半径",
                            value: "1/2孢子半径至孢子半径"
                        }, {
                            name: "等于孢子半径",
                            nameEN: "等于孢子半径",
                            value: "等于孢子半径"
                        }]
                    }, {
                        name: "缝开裂程度",
                        nameEN: "缝开裂程度",
                        value: "degreeOfCrack",
                        list: [{
                            name: "不开裂",
                            nameEN: "不开裂",
                            value: "不开裂"
                        }, {
                            name: "开裂",
                            nameEN: "开裂",
                            value: "开裂"
                        }, {
                            name: "裂成三角区",
                            nameEN: "裂成三角区",
                            value: "裂成三角区"
                        }]
                    }, {
                        name: "缝状态",
                        nameEN: "缝状态",
                        value: "stateOfSutura",
                        list: [{
                            name: "微弱",
                            nameEN: "微弱",
                            value: "微弱"
                        }, {
                            name: "明显",
                            nameEN: "明显",
                            value: "明显"
                        }, {
                            name: "粗强",
                            nameEN: "粗强",
                            value: "粗强"
                        }, {
                            name: "直",
                            nameEN: "直",
                            value: "直"
                        }, {
                            name: "弯曲",
                            nameEN: "弯曲",
                            value: "弯曲"
                        }, {
                            name: "隆起",
                            nameEN: "隆起",
                            value: "隆起"
                        }, {
                            name: "末端分叉",
                            nameEN: "末端分叉",
                            value: "末端分叉"
                        }]
                    }, {
                        name: "唇",
                        nameEN: "唇",
                        value: "lip",
                        list: [{
                            name: "不具唇",
                            nameEN: "不具唇",
                            value: "不具唇"
                        }, {
                            name: "具弱唇",
                            nameEN: "具弱唇",
                            value: "具弱唇"
                        }, {
                            name: "具厚唇",
                            nameEN: "具厚唇",
                            value: "具厚唇"
                        }]
                    }]
                },
                {
                    id: 2,
                    name: "孔",
                    nameEN: "porus",
                    value: "孔",
                    type: "AE",
                    chlidren: [{
                        name: "孔数量",
                        nameEN: "孔数量",
                        value: "numberOfHoles",
                        list: [{
                            name: "1",
                            nameEN: "1",
                            value: "1"
                        }, {
                            name: "2",
                            nameEN: "2",
                            value: "2"
                        },
                        {
                            name: "3",
                            nameEN: "3",
                            value: "3"
                        }, {
                            name: "4",
                            nameEN: "4",
                            value: "4"
                        },
                        {
                            name: "5",
                            nameEN: "5",
                            value: "5"
                        },
                        {
                            name: "多孔",
                            nameEN: "多孔",
                            value: "多孔"
                        }, {
                            name: "散孔",
                            nameEN: "panpori",
                            value: "散孔"
                        },]
                    },
                    {
                        name: "孔位置",
                        nameEN: "孔位置",
                        value: "positionOfHole",
                        list: [{
                            name: "赤道",
                            nameEN: "赤道",
                            value: "赤道"
                        }, {
                            name: "赤道角端",
                            nameEN: "赤道角端",
                            value: "赤道角端"
                        }, {
                            name: "辐间区中部",
                            nameEN: "辐间区中部",
                            value: "辐间区中部"
                        }, {
                            name: "亚赤道",
                            nameEN: "亚赤道",
                            value: "亚赤道"
                        }, {
                            name: "非赤道",
                            nameEN: "非赤道",
                            value: "非赤道"
                        }, {
                            name: "突出于赤道",
                            nameEN: "突出于赤道",
                            value: "突出于赤道"
                        }, {
                            name: "远极",
                            nameEN: "远极",
                            value: "远极"
                        }, {
                            name: "正形粉类",
                            nameEN: "正形粉类",
                            value: "正形粉类"
                        }]
                    }, {
                        name: "孔形状",
                        nameEN: "孔形状",
                        value: "shapeOfHole",
                        list: [{
                            name: "横长",
                            nameEN: "横长",
                            value: "横长"
                        }, {
                            name: "矩形",
                            nameEN: "rectangle",
                            value: "矩形"
                        }, {
                            name: "圆形（椭圆形）",
                            nameEN: "round（oval）",
                            value: "圆形（椭圆形）"
                        }, {
                            name: "菱形",
                            nameEN: "rhombus",
                            value: "菱形"
                        }, {
                            name: "拟孔",
                            nameEN: "拟孔",
                            value: "拟孔"
                        }, {
                            name: "圆形凹陷区",
                            nameEN: "圆形凹陷区",
                            value: "圆形凹陷区"
                        }, {
                            name: "倒漏斗形",
                            nameEN: "倒漏斗形",
                            value: "倒漏斗形"
                        }]
                    }, {
                        name: "孔大小",
                        nameEN: "孔大小",
                        value: "sizeOfHole",
                        list: [{
                            name: "小",
                            nameEN: "little",
                            value: "小"
                        }, {
                            name: "大",
                            nameEN: "big",
                            value: "大"
                        }, {
                            name: "相等",
                            nameEN: "equality",
                            value: "相等"
                        }, {
                            name: "不相等",
                            nameEN: "unequal",
                            value: "不相等"
                        }]
                    }, {
                        name: "孔径",
                        nameEN: "孔径",
                        value: "pore",
                        list: []
                    }, {
                        name: "孔环",
                        nameEN: "annulus",
                        value: "annulus",
                        list: [{
                            name: "无",
                            nameEN: "not",
                            value: "无"
                        }, {
                            name: "窄",
                            nameEN: "narrow",
                            value: "窄"
                        }, {
                            name: "宽",
                            nameEN: "wide",
                            value: "宽"
                        }]
                    }, {
                        name: "环宽度",
                        nameEN: "环宽度",
                        value: "widthOfAnnulus",
                        list: []
                    }, {
                        name: "孔缘",
                        nameEN: "孔缘",
                        value: "peritreme",
                        list: [{
                            name: "平滑",
                            nameEN: "平滑",
                            value: "平滑"
                        }, {
                            name: "不平滑",
                            nameEN: "不平滑",
                            value: "不平滑"
                        }, {
                            name: "锯齿状",
                            nameEN: "锯齿状",
                            value: "锯齿状"
                        }, {
                            name: "破裂状",
                            nameEN: "破裂状",
                            value: "破裂状"
                        }, {
                            name: "具瘤",
                            nameEN: "具瘤",
                            value: "具瘤"
                        }, {
                            name: "具颗粒",
                            nameEN: "具颗粒",
                            value: "具颗粒"
                        }, {
                            name: "加厚",
                            nameEN: "加厚",
                            value: "加厚"
                        }]
                    }, {
                        name: "孔盖",
                        nameEN: "opercule",
                        value: "opercule",
                        list: [{
                            name: "无",
                            nameEN: "not",
                            value: "无"
                        }, {
                            name: "有",
                            nameEN: "have",
                            value: "有"
                        }, {
                            name: "加厚",
                            nameEN: "加厚",
                            value: "加厚"
                        }]
                    }, {
                        name: "孔庭",
                        nameEN: "孔庭",
                        value: "kongTing",
                        list: [{
                            name: "无",
                            nameEN: "not",
                            value: "无"
                        }, {
                            name: "有",
                            nameEN: "have",
                            value: "有"
                        }, {
                            name: "大",
                            nameEN: "big",
                            value: "大"
                        }]
                    }, {
                        name: "分布",
                        nameEN: "分布",
                        value: "distributionOfHoles",
                        list: [{
                            name: "均匀分布",
                            nameEN: "均匀分布",
                            value: "均匀分布"
                        }, {
                            name: "不均匀分布",
                            nameEN: "不均匀分布",
                            value: "不均匀分布"
                        }]
                    }, {
                        name: "孔间",
                        nameEN: "mesoporium",
                        value: "mesoporium",
                        list: [{
                            name: "无",
                            nameEN: "not",
                            value: "无"
                        }, {
                            name: "具肋",
                            nameEN: "arcus",
                            value: "具肋"
                        }, {
                            name: "具加厚带",
                            nameEN: "具加厚带",
                            value: "具加厚带"
                        }]
                    }, {
                        name: "外壁在孔处",
                        nameEN: "外壁在孔处",
                        value: "atTheHole",
                        list: [{
                            name: "加厚",
                            nameEN: "加厚",
                            value: "加厚"
                        }, {
                            name: "不加厚",
                            nameEN: "不加厚",
                            value: "不加厚"
                        }, {
                            name: "突起（翘起）",
                            nameEN: "突起（翘起）",
                            value: "突起（翘起）"
                        }]
                    }]
                },
                {
                    id: 3,
                    name: "沟",
                    nameEN: "colpus",
                    value: "沟",
                    type: "DE",
                    chlidren: [{
                        name: "沟数量",
                        nameEN: "沟数量",
                        value: "numberOfGroove",
                        list: [{
                            name: "原始沟亚类",
                            nameEN: "原始沟亚类",
                            value: "原始沟亚类"
                        }, {
                            name: "多沟肋亚类",
                            nameEN: "多沟肋亚类",
                            value: "多沟肋亚类"
                        },
                        {
                            name: "单沟亚类",
                            nameEN: "单沟亚类",
                            value: "单沟亚类"
                        }, {
                            name: "双沟亚类",
                            nameEN: "双沟亚类",
                            value: "双沟亚类"
                        },
                        {
                            name: "三沟亚类",
                            nameEN: "三沟亚类",
                            value: "三沟亚类"
                        },
                        {
                            name: "多沟亚类",
                            nameEN: "多沟亚类",
                            value: "多沟亚类"
                        }, {
                            name: "三突起粉类",
                            nameEN: "三突起粉类",
                            value: "三突起粉类"
                        },]
                    },
                    {
                        name: "沟位置",
                        nameEN: "沟位置",
                        value: "positionOfGroove",
                        list: [{
                            name: "子午向",
                            nameEN: "子午向",
                            value: "子午向"
                        }, {
                            name: "角端",
                            nameEN: "角端",
                            value: "角端"
                        }, {
                            name: "辐间区中部",
                            nameEN: "辐间区中部",
                            value: "辐间区中部"
                        }]
                    }, {
                        name: "沟类型",
                        nameEN: "type",
                        value: "typeOfGroove",
                        list: [{
                            name: "合沟",
                            nameEN: "合沟",
                            value: "合沟"
                        }, {
                            name: "副合沟",
                            nameEN: "副合沟",
                            value: "副合沟"
                        }]
                    }, {
                        name: "沟长度",
                        nameEN: "沟长度",
                        value: "lengthOfGroove",
                        list: [{
                            name: "短沟",
                            nameEN: "brevicolpati",
                            value: "短沟"
                        }, {
                            name: "长沟",
                            nameEN: "longicolpati",
                            value: "长沟"
                        }, {
                            name: "合沟",
                            nameEN: "syncolpati",
                            value: "合沟"
                        }]
                    }, {
                        name: "沟宽度",
                        nameEN: "沟宽度",
                        value: "widthOfGroove",
                        list: [{
                            name: "不开裂",
                            nameEN: "不开裂",
                            value: "不开裂"
                        }, {
                            name: "开裂",
                            nameEN: "开裂",
                            value: "开裂"
                        },
                        {
                            name: "等宽",
                            nameEN: "等宽",
                            value: "等宽"
                        }, {
                            name: "中部宽，两端窄",
                            nameEN: "中部宽，两端窄",
                            value: "中部宽，两端窄"
                        },
                        {
                            name: "宽阔",
                            nameEN: "宽阔",
                            value: "宽阔"
                        },
                        {
                            name: "细",
                            nameEN: "细",
                            value: "细"
                        }, {
                            name: "呈V形",
                            nameEN: "呈V形",
                            value: "呈V形"
                        },]
                    }, {
                        name: "伸达两极",
                        nameEN: "伸达两极",
                        value: "reachToThePoles",
                        list: [{
                            name: "是",
                            nameEN: "yes",
                            value: "是"
                        }, {
                            name: "否",
                            nameEN: "no",
                            value: "否"
                        }, {
                            name: "几达极区",
                            nameEN: "几达极区",
                            value: "几达极区"
                        }]
                    }, {
                        name: "两端",
                        nameEN: "both ends",
                        value: "twoEndsOfGroove",
                        list: [{
                            name: "锐",
                            nameEN: "sharp",
                            value: "锐"
                        }, {
                            name: "圆",
                            nameEN: "circle",
                            value: "圆"
                        }]
                    }, {
                        name: "沟膜",
                        nameEN: "colpus membrane",
                        value: "colpusMembrane",
                        list: [{
                            name: "无",
                            nameEN: "not",
                            value: "无"
                        }, {
                            name: "有",
                            nameEN: "have",
                            value: "有"
                        }, {
                            name: "具纹饰",
                            nameEN: "具纹饰",
                            value: "具纹饰"
                        }, {
                            name: "具瘤纹",
                            nameEN: "具瘤纹",
                            value: "具瘤纹"
                        }, {
                            name: "具网纹",
                            nameEN: "具网纹",
                            value: "具网纹"
                        }]
                    }, {
                        name: "沟间",
                        nameEN: "intercolpar",
                        value: "intercolpar",
                        list: [{
                            name: "具肋",
                            nameEN: "have",
                            value: "具肋"
                        }, {
                            name: "不具肋",
                            nameEN: "not",
                            value: "不具肋"
                        }]
                    }, {
                        name: "沟边缘",
                        nameEN: "costa colpi",
                        value: "costaColpi",
                        list: [{
                            name: "不具唇",
                            nameEN: "不具唇",
                            value: "不具唇"
                        }, {
                            name: "具唇",
                            nameEN: "具唇",
                            value: "具唇"
                        }, {
                            name: "加厚",
                            nameEN: "加厚",
                            value: "加厚"
                        }]
                    }]
                },
                {
                    id: 4,
                    name: "孔沟",
                    nameEN: "孔沟",
                    value: "colporate",
                    type: "E",
                    chlidren: [{
                        name: "孔沟类型",
                        nameEN: "孔沟类型",
                        value: "colporate",
                        list: [{
                            name: "三孔沟",
                            nameEN: "tricolporate",
                            value: "三孔沟"
                        }, {
                            name: "多孔沟",
                            nameEN: "polycolporate",
                            value: "多孔沟"
                        },
                        {
                            name: "合沟孔",
                            nameEN: "syncolporate",
                            value: "合沟孔"
                        }, {
                            name: "环极沟",
                            nameEN: "zonocolpate",
                            value: "环极沟"
                        },
                        {
                            name: "拟孔沟",
                            nameEN: "colporoidate",
                            value: "拟孔沟"
                        }, {
                            name: "边沟孔",
                            nameEN: "边沟孔",
                            value: "边沟孔"
                        },]
                    }]
                }]
            }
        }
    },
    methods: {
        ...mapMutations(['SET_FORM_LIST']),
        // 录入
        async getprops() {
            let option = this.initdata(this.formLabelAlign)
            let { data } = await formApi.getTemplate(option)
            this.SET_FORM_LIST(data);
            this.$router.push({
                name: 'form',
                params: option
            });
        },
        // 表单重置
        resetForm(formName) {
            let obj = {
                formDetails: {},
                name: '',
                shape: '',
                type: '',
                size: '',
                gzlength: '',
                age: {
                    id: []
                },
                line: '',
                ply: '',
                thickness: '',
                emblazonry: {
                    id: []
                },
                far: '',
                near: '',
                loop: {
                    id: []
                },
                perisporium: {},
                bladder: {
                    id: []
                },
                trophi: {
                    id: []
                },
            }
            this.$nextTick(() => {
                // this.formLabelAlign.emblazonry = {
                //     id: 0
                // }
                Object.assign(this.formLabelAlign, obj)
            })

        },
        // 判断该选项是否能显示
        getflag(str) {
            let flag = this.formLabelAlign.classification
            // 离散形式
            if ((flag == '菌类孢子' || flag == '蕨类植物孢子' || flag == '裸子植物花粉' || flag == '被子植物花粉') && str == 'name') {
                return true
            }

            // 形状
            if ((flag == '菌类孢子' || flag == '蕨类植物孢子' || flag == '被子植物花粉') && str == 'shape') {
                return true
            }

            // 侧面轮廓
            if (flag == '被子植物花粉' && str == 'type') {
                return true
            }

            // 大小
            if (flag == '菌类孢子' && str == 'size') {
                return true
            }

            // 大小
            if (flag == '被子植物花粉' && str == 'gzlength') {
                return true
            }

            // 赤道轮廓
            if ((flag == '蕨类植物孢子' || flag == '被子植物花粉') && str == 'age') {
                return true
            }

            // 轮廓线
            if ((flag == '菌类孢子' || flag == '蕨类植物孢子' || flag == '被子植物花粉') && str == 'line') {
                return true
            }

            // 外壁厚度
            if ((flag == '菌类孢子' || flag == '蕨类植物孢子' || flag == '被子植物花粉') && str == 'ply') {
                return true
            }

            // 厚度（微米）
            if ((flag == '菌类孢子' || flag == '蕨类植物孢子' || flag == '被子植物花粉') && str == 'thickness') {
                return true
            }

            // 表面纹饰
            if ((flag == '菌类孢子' || flag == '蕨类植物孢子' || flag == '裸子植物花粉' || flag == '被子植物花粉') && str == 'emblazonry') {
                return true
            }

            // 远极面纹饰
            if ((flag == '蕨类植物孢子' || flag == '被子植物花粉') && str == 'far') {
                return true
            }

            // 近极面纹饰
            if ((flag == '蕨类植物孢子' || flag == '被子植物花粉') && str == 'near') {
                return true
            }

            // 环
            if (flag == '蕨类植物孢子' && str == 'loop') {
                return true
            }

            // 周壁
            if ((flag == '蕨类植物孢子' || flag == '被子植物花粉') && str == 'perisporium') {
                return true
            }

            // 囊
            if (flag == '裸子植物花粉' && str == 'bladder') {
                return true
            }

            // 萌发口器
            if ((flag == '菌类孢子' || flag == '蕨类植物孢子' || flag == '裸子植物花粉' || flag == '被子植物花粉') && str == 'trophi') {
                return true
            }

        },
        clicka() {
        },
        // 判断子选项是否显示
        getcflag(str, id) {
            str = str.replace("A", "菌类孢子")
            str = str.replace("B", "藻类")
            str = str.replace("C", "蕨类植物孢子")
            str = str.replace("D", "裸子植物花粉")
            str = str.replace("E", "被子植物花粉")
            let flag = str.indexOf(this.formLabelAlign.classification) != -1
            return flag
        },
        initdata(data) {

            let formDetails = data.formDetails
            // 赤道轮廓
            formDetails.equatorialContour = data.age.id.map((v) => {
                return this.selectList.age[v].name
            })

            // 表面纹饰
            formDetails.surfaceOrnamentation = data.emblazonry.id.map((v) => {
                return this.selectList.emblazonry[v].name
            })

            // 环
            formDetails.zona = data.loop.id.map((v) => {
                return this.selectList.loop[v].name
            })

            // 囊
            formDetails.saccus = data.bladder.id.map((v) => {
                return this.selectList.bladder[v].name
            })

            // 萌发口器
            formDetails.aperture = data.trophi.id.map((v) => {
                return this.selectList.trophi[v].name
            })

            for (let val in formDetails) {
                if (Array.isArray(formDetails[val])) {
                    formDetails[val] = formDetails[val].join(",")
                }
            }

            let obj = {
                genus: data.genus,
                nomenclator: data.nomenclator,
                defineYear: data.defineYear,
                reviser: data.reviser,
                reviseryear: data.reviseryear,
                nameCn: data.nameCn,
                typeSpecies: data.typeSpecies,
                remark1: data.remark1,
                classification: data.classification,
                affiliation: data.affiliation,
                ageFrom: data.ageFrom,
                ageTo: data.ageTo,
                locality: data.locality,
                horizon: data.horizon,
                formDetails,
                remark2: data.remark2,
                genericCharacters: data.genericCharacters,
            }

            for (let val in obj) {
                if (Array.isArray(obj[val])) {
                    obj[val] = obj[val].join(",")
                }
            }
            return obj

        }
    },
}
</script>

<style lang="scss" scoped>
.all {
    width: 73%;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);

    .title {
        box-sizing: border-box;
        padding: 16px 26px;
        font-size: 20px;
        font-weight: 800;
        color: #333333;
        width: 100%;
        border-bottom: 1px solid #D6D6D6;
    }

    .btn {
        width: 50%;
        margin-left: 25%;
        margin-bottom: 45px;
        height: 50px;
        background: #2B82E3;
        font-weight: 800;
        border-radius: 0;

        span {
            font-weight: 800;
        }
    }

    .form {
        padding: 42px;

        input {
            border-radius: 0px !important;
        }

        ::v-deep .el-form-item {
            width: 50%;
        }

        ::v-deep .el-input {
            caret-color: black;
            width: 270px;
        }

        ::v-deep .el-form-item__label {
            position: relative;
            font-weight: 800;

            &::after {
                position: absolute;
                content: "";
                width: 3px;
                height: 16px;
                background-color: red;
                display: block;
                top: 12px;
                left: -10px;
            }
        }

        ::v-deep .el-select {
            width: 270px;
        }

        ::v-deep .el-form-item__label {
            width: 90px !important;
            padding: 0 5px 0 0 !important;
        }

        .chlidren {
            display: flex;
            flex-wrap: wrap;
            // padding-left: 82px;

            .el-form-item {
                width: 40%;
                margin-left: 90px;

                ::v-deep .el-form-item__label {
                    &::after {
                        display: none;
                    }
                }
            }

            .input {
                caret-color: black;
                width: 270px !important;
            }

        }

        .else {
            ::v-deep .el-input {
                caret-color: black;
                width: 50% !important;
            }
        }



        .red {
            ::v-deep .el-form-item__label {
                &::after {
                    background-color: red;
                }
            }
        }

        .blue {
            ::v-deep .el-form-item__label {
                &::after {
                    background-color: blue;
                }
            }
        }

        .green {
            ::v-deep .el-form-item__label {
                &::after {
                    background-color: green;
                }
            }
        }

        .orange {
            ::v-deep .el-form-item__label {
                &::after {
                    background-color: orange;
                }
            }
        }
    }
}
</style>