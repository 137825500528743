import request from "../request";
// 形态模板下载
function importTemplate() {
  return request({
    url: "/image/form/importTemplate",
    method: "get",
  });
}


// 形态ex表导入
function excelImport(data) {
    return request({
      url: "/image/form/excelImport",
      method: "post",
      data,
    });
}


// 上传文件
function upfile(data){
  return request({
    url: "/common/upload",
    method: "post",
    data,
  });
}

// 形态数据录入
function addform(data) {
  return request({
    url: "/image/form",
    method: "post",
    data,
  });
}

// 形态信息导出
function exportTemplate(data) {
    return request({
      url: "/image/form/export",
      method: "post",
      data,
    });
}

// 查询形态列表
function getTemplate(data,pageNum=1,pageSize=10) {
    return request({
      url: `/image/form/list/${pageNum}/${pageSize}`,
      method: "post",
      data,
    });
}




export default {
    importTemplate,
    excelImport,
    exportTemplate,
    getTemplate,
    addform,
    upfile
};
